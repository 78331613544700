import { omit } from 'lodash';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

// Locals
import { groups as groupsApi } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

const groupEntity = new schema.Entity('groups');
const groupSchema = { groups: [groupEntity] };

const initialState = Immutable({
  result: {
    groups: []
  },
  entities: {
    groups: {}
  },
  last: true,
  totalElements: null,
  totalPages: null,
  sort: [],
  size: 10,
  number: 0,
  first: null,
  numberOfElements: null,
  loading: {
    groups: false
  },
  error: null,
  status: [],
  search: '',
  currentPage: 0,
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    group: null
  },
  modalDetails: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    group: null,
    policy: null
  }
});

const groupsModel = {
  name: 'groups',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setEntity(state, { groups, params, data }) {
      return state.merge({ ...groups, ...params, data });
    },
    setError: (state, error) => state.merge({ error }),
    setModal(state, payload) {
      return state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      });
    },
    setModalDetails(state, payload) {
      return state.merge({
        modalDetails: {
          ...state.modalDetails,
          ...payload
        }
      });
    },
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setActionModalDetails: (state, payload) =>
      state.merge({
        modalDetails: {
          ...state.modalDetails,
          ...payload
        }
      }),
    setModalLoading(state, loading) {
      return state.merge({
        modal: {
          ...state.modal,
          loading
        }
      });
    },
    setModalDetailsLoading(state, loading) {
      return state.merge({
        modalDetails: {
          ...state.modalDetails,
          loading
        }
      });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    },
    setCurrentPage(state, page) {
      return state.merge({
        currentPage: page
      });
    },
    reset: () => initialState
  },
  effects: dispatch => ({
    async find(params = {}, state) {
      try {
        const request = await groupsApi.find({
          realm: state.application.realm.realm,
          search: {
            page: state?.groups?.number,
            size: state?.groups?.size,
            disabled: false,
            ...state?.groups?.search,
            ...params
          }
        });
        dispatch.groups.setCurrentPage(params?.page);
        const groupsData = normalize(
          {
            groups: request?.data?.groups?.content
          },
          groupSchema
        );

        dispatch.groups.setEntity({
          groups: groupsData,
          params: omit(request?.data?.groups, ['content']),
          data: request?.data?.groups?.content
        });
      } catch (e) {
        dispatch.groups.setError(e);
      }
    },
    async getIntegrationsGroup(groupName, state) {
      try {
        const request = await groupsApi.getIntegrations({
          realm: state.application.realm.realm,
          search: {
            groupName
          }
        });
        dispatch.groups.setModalDetails({
          integrationsGroup: Boolean(
            request?.data?.samlGroupMapping?.totalElements
          )
        });
      } catch (e) {
        dispatch.groupsIntegration.setError(e);
      }
    },
    async getFirstPolicyGroup(name, state) {
      try {
        const request = await groupsApi.getFirstPolicyGroup({
          realm: state.application.realm.realm,
          name
        });
        dispatch.groups.setModalDetails({
          policy: request?.data?.getFirstPolicyGroup
        });
      } catch (e) {
        dispatch.groups.setModalDetails({
          policy: null
        });
      }
    },
    async create(params, state) {
      try {
        const response = await groupsApi.create({
          realm: state.application.realm.realm,
          ...params
        });
        dispatch.groups.setCurrentPage(0);
        dispatch.groups.setActionModal(
          { success: response },
          {
            snackbar: {
              text: i18n.t(
                'scenes.groups.messages.success.group_successfully_created',
                {
                  groupName: params?.name
                }),
              success: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );

        params?.props?.resetForm();
      } catch (e) {
        dispatch.groups.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      }
    },
    async edit(params, state) {
      try {
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: params?.explanation
        });
        const response = await groupsApi.edit({
          realm: state?.application?.realm?.realm,
          group: {
            id: params?.id,
            name: params?.name,
            description: params?.description,
            members: params?.members
          },
          policy: {
            id: params?.policyId,
            name: params?.name,
            description: params?.description,
            bindings: params?.bindings?.map?.(binding => ({
              ...binding,
              groups: [params?.id],
              role: binding?.role?.value,
              envs: binding?.envs?.map?.(env => env?.value)
            }))
          }
        });
        dispatch.groups.setActionModalDetails(
          { success: response },
          {
            snackbar: {
              text: i18n.t(
                'scenes.groups.messages.success.group_successfully_modified',
                {
                  groupName: params?.name
                }),
              success: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } catch (e) {
        dispatch.groups.setActionModalDetails(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } finally {
        dispatch.dialogs.reset();
      }
    },
    async restore(params, state) {
      const { groups, name } = params;
      try {
        const { success, data, error } = await groupsApi.restore({
          realm: state.application.realm.realm,
          ids: groups
        });
        if (!success) throw new Error(error?.message || 'API Error');
        if (name) {
          dispatch.groups.setActionModal(
            {
              success: data
            },
            {
              snackbar: {
                text: i18n.t('label.archived_group_msg_success', {
                  groupName: name
                }),
                success: true,
                open: true,
                action: {
                  label: i18n.t('action.ok')
                }
              }
            }
          );
        }
      } catch (error) {
        if (name) {
          dispatch.groups.setActionModal(
            {
              error: error.message
            },
            {
              snackbar: {
                text: i18n.t('label.archived_group_msg_alert', {
                  groupName: name
                }),
                danger: true,
                open: true,
                action: {
                  label: i18n.t('action.ok')
                }
              }
            }
          );
        }
      }
    },
    async remove(params, state) {
      try {
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: params?.explanation
        });
        const remove = await groupsApi.remove({
          realm: state.application.realm.realm,
          explanation: state?.dialogs?.confirmDialog?.explanation?.value,
          ...params
        });
        dispatch.groups.setCurrentPage(0);
        dispatch.groups.setActionModal(
          {
            success: remove
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.groups.messages.success.group_successfully_removed',
                {
                  groupName: params?.name
                }),
              success: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      } catch (e) {
        dispatch.groups.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true,
              action: {
                label: 'Ok'
              }
            }
          }
        );
      }
    }
  }),
  logics: [
    {
      type: 'groups/find',
      latest: true,
      process(context, dispatch, done) {
        dispatch.groups.setLoading({ path: 'groups', value: true });
        done();
      }
    },
    {
      type: ['groups/setError', 'groups/setEntity'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.groups.setLoading({ path: 'groups', value: false });
        done();
      }
    },
    {
      type: ['groups/setActionModal'],
      latest: true,
      process(context, dispatch, done) {
        const { user: { status }, groups: { currentPage } } = context.getState();
        const { payload } = context.action;
        if (payload.success) {
          dispatch.groups.setModalLoading(!!payload.success);
          dispatch.groups.setModal({
            visible: false,
            loading: false,
            success: true
          });
          dispatch.groups.find({ status, page: currentPage });
        } else {
          dispatch.groups.setModal({ loading: false, success: false });
        }
        done();
      }
    },
    {
      type: ['groups/setActionModalDetails'],
      latest: true,
      process(context, dispatch, done) {
        const { user: { status }, groups: { currentPage } } = context.getState();
        const { payload } = context.action;
        if (payload.success) {
          dispatch.groups.setModalDetailsLoading(!!payload.success);
          dispatch.groups.setModalDetails({
            visible: false,
            loading: false,
            success: true
          });
          dispatch.groups.find({ status, page: currentPage });
        } else {
          dispatch.groups.setModalDetails({ loading: false, success: false });
        }
        done();
      }
    },
    {
      type: ['groups/create'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.groups.setModalLoading(true);
        done();
      }
    },
    {
      type: ['groups/edit'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.groups.setModalDetailsLoading(true);
        done();
      }
    },
    {
      type: ['groups/setSearch'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.groups.find({
          page: 0
        });
        done();
      }
    }
  ]
};

export default groupsModel;
