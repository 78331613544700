import DeployPipelineWithServerless from './DeployPipelineWithServerless';
import OldDeployPipeline from './OldDeployPipeline';
import { useIsAutoscaling } from '../../hooks/useIsAutoscaling';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

export const DeployPipeline = (props: any) => {
  const { RUN_SET_ENGINE_VERSION } = featureFlagConstants;
  const { RUN_ENGINE_VERSION_SELECT } = featureFlagConstants;
  const { treatments } = useFeatureFlag([
    RUN_SET_ENGINE_VERSION,
    RUN_ENGINE_VERSION_SELECT
  ]);

  const showNewAutoScalingExperience = useIsAutoscaling();

  const isPipelineEngine =
    treatments[RUN_SET_ENGINE_VERSION].treatment === 'on';

  const isPipelineEngineSelect =
    treatments[RUN_ENGINE_VERSION_SELECT].treatment === 'on';

  if (!showNewAutoScalingExperience) {
    return (
      <OldDeployPipeline
        {...props}
        isPipelineEngine={isPipelineEngine}
        isPipelineEngineSelect={isPipelineEngineSelect}
      />
    );
  }

  return (
    <DeployPipelineWithServerless
      {...props}
      isPipelineEngine={isPipelineEngine}
      isPipelineEngineSelect={isPipelineEngineSelect}
    />
  );
};
