/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import * as cronjsMatcher from '@datasert/cronjs-matcher';
import { useFormik } from 'formik';
import { debounce, head } from 'lodash';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import moment from 'moment';
import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import calcLicenses from './common/calcLicense';
import { useIsAutoscaling } from '../../hooks/useIsAutoscaling';
import { isAutoScalingDeploy } from '../../utils/isAutoScalingDeploy';

import apiConfiguration from '~/api/configuration';
import apiLicenseStatus from '~/api/licenseStatus';
import apiPipeline from '~/api/pipeline';
import api from '~/api/pipelines';
import apiRuntime from '~/api/runtime';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import withHooks from '~/common/hoc/withHooks';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

const PipelineSchema = yup.object().shape({
  isReplicas: yup.boolean(),
  isPipelineEngine: yup.boolean(),
  pipeline: yup.object().shape({
    value: yup.string().required('common.messages.error.required_field'),
    label: yup.string().required('common.messages.error.required_field')
  }),
  version: yup.object().shape({
    value: yup.string().required('common.messages.error.required_field'),
    label: yup.string().required('common.messages.error.required_field')
  }),
  replicasDeploy: yup.object().when('isReplicas', (isReplicas, schema) => {
    if (isReplicas) {
      return schema.shape({
        value: yup.string().required('common.messages.error.required_field'),
        label: yup.string().required('common.messages.error.required_field')
      });
    }
    return schema.shape({
      value: yup.string(),
      label: yup.string()
    });
  }),
  configuration: yup.string().required('common.messages.error.required_field'),
  replicas: yup
    .number()
    .required('common.messages.error.replicas_are_required')
    .when('isAutoScaling', {
      is: true,
      then: yup
        .number()
        .test(
          'is-greater-than-or-equal-to-minReplicas',
          'Replicas must be greater than or equal to minimum replicas when autoscaling is enabled',
          function (value) {
            return value >= this.parent.minReplicas;
          }
        ),
      otherwise: yup.number()
    }),
  minReplicas: yup.number().when('isAutoScaling', {
    is: true,
    then: yup
      .number()
      .required('Minimum replicas are required when autoscaling is enabled')
      .min(0, 'Minimum replicas must be at least 0')
  }),
  consumer: yup.number().required('Consumer is required')
});

const isDefaultAutoScaling = ({ cronExpression, cronFlagMinutes }) => {
  if (!cronExpression || !cronFlagMinutes) return false;

  try {
    const futureMatches = cronjsMatcher.getFutureMatches(cronExpression, {
      matchCount: 2,
      hasSeconds: true
    });

    return (
      Math.abs(
        new Date(futureMatches[1]).getMinutes() -
          new Date(futureMatches[0]).getMinutes()
      ) >= cronFlagMinutes
    );
  } catch (e) {
    console.warn(e);
    return false;
  }
};

const createObjectPipelineVersion = pipeline => ({
  value: pipeline.id,
  label: `${pipeline?.versionMajor}.${pipeline?.versionMinor}`
});

const createObjectOption = deployment => {
  if (deployment?.replicaName) {
    const removeNameMultiInstanceThePipeline =
      deployment?.pipeline?.name?.replace?.(`-${deployment?.replicaName}`, '');
    return {
      label: `${removeNameMultiInstanceThePipeline} - V${deployment?.pipeline?.versionMajor}`,
      value: deployment?.pipeline.id
    };
  }
  return {
    label: `${deployment?.pipeline?.name} - V${deployment?.pipeline?.versionMajor}`,
    value: deployment?.pipeline.id
  };
};
const normalizePipelines = pipelines => {
  const data = map(pipelines, pipeline => ({
    label: `${pipeline.name} - V${pipeline.versionMajor}`,
    value: pipeline?._id
  }));
  return data;
};
const normalizeSize = name => {
  if (!name) return '';
  return name?.split('-')[0];
};

const normalizeNamePipeline = (name, multiInstanceName) =>
  multiInstanceName ? `${name}-${multiInstanceName}` : name;

const createObjectViewDeploy = data => {
  const name = normalizeNamePipeline(
    data?.pipeline.name,
    data?.parameterizedReplica
  );
  return {
    ...data,
    pipeline: {
      ...data?.pipeline,
      name
    },
    activeConfiguration: {
      ...data.activeConfiguration,
      actualConsumers: data.consumers
    },
    licenses: calcLicenses(data?.activeConfiguration?.name, data?.replicas),
    availableReplicas: `${data.replicas}/${data.replicas}`,
    errorCount: 0,
    oomCount: 0,
    status: { text: 'STARTING' }
  };
};

const enhancer = withHooks(props => {
  const [projectId, setProjectId] = useState(null);
  const [configurations, setConfigurations] = useState(null);
  const [pipelineInstances, setPipelineInstences] = useState(null);
  const [validationLicense, setValidationLicense] = useState([]);
  const [validationPipeline, setValidationPipeline] = useState([]);
  const [configurationLastDeploy, setConfigurationLastDeploy] = useState(null);
  const [sliderProps, setSliderProps] = useState({ max: 0 });
  const [openPromote, setOpenPromote] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [newDeploy, setNewDeploy] = useState();
  const [loadingPromote, setLoadingPromote] = useState(false);
  const deploymentSelect = props.showPromote ? props.deploymentSelected : false;
  const deployment =
    deploymentSelect ||
    useSelector(state => state?.runtime?.deployment, isEqual);
  const [newDeployment, setNewDeployment] = useState(deployment);
  const [pipeline, setPipeline] = useState(deploymentSelect?.pipeline);
  const [triggers, setTriggers] = useState([]);
  const [isOpenAdvancedSettings, setIsOpenAdvancedSettings] = useState(false);

  const application = useSelector(state => state.application);
  const showInactiveTriggersExperience = useIsAutoscaling();

  async function getTriggers() {
    const { data } = await apiRuntime.getTriggers({
      realm: application.activeRealm
    });
    setTriggers(data.triggers);
  }

  useEffect(() => {
    getTriggers();
  }, []);

  const handleToggle = () => setIsOpenAdvancedSettings(!isOpenAdvancedSettings);

  const pipelineRedeploy = useSelector(
    state => state?.runtime?.pipeline,
    isEqual
  );
  const dispatch = useDispatch();

  const {
    RUN_CHANGE_FETCHAPI,
    RUN_ROUTES_PARAMS,
    RUN_CORE_ENGINE_DEPLOY_KEDA,
    RUN_SET_ENGINE_VERSION
  } = featureFlagConstants;
  const { treatments } = useFeatureFlag(
    [
      RUN_CHANGE_FETCHAPI,
      RUN_ROUTES_PARAMS,
      RUN_CORE_ENGINE_DEPLOY_KEDA,
      RUN_SET_ENGINE_VERSION
    ],
    {
      realm: props?.realm
    }
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const isNewRoutesParams = treatments[RUN_ROUTES_PARAMS].treatment === 'on';
  const isNewFetchPipeline = treatments[RUN_CHANGE_FETCHAPI].treatment === 'on';
  const isPipelineEngine =
    treatments[RUN_SET_ENGINE_VERSION].treatment === 'on';

  const texts = {
    deploy: {
      title: 'scenes.runtime.labels.deploy_pipeline',
      button: 'common.actions.deploy',
      modalConfirmation: {
        title: 'label.deploy_pipeline_prompt',
        text: 'label.deploy_pipeline_msg',
        confirmText: 'common.actions.deploy'
      },
      datatestidText: 'run-confirm-deploy'
    },
    redeploy: {
      title: 'label.redeploy_pipeline',
      button: 'action.redeploy',
      modalConfirmation: {
        title: 'label.redeploy_pipeline_prompt',
        text: 'label.redeploy_pipeline_msg',
        confirmText: 'action.redeploy'
      },
      datatestidText: 'run-confirm-redeploy'
    },
    promote: {
      title: 'label.pipeline_promotion_configuration',
      button: 'action.promote',
      datatestidText: 'run-confirm-promote'
    }
  };

  const trigger = find(
    triggers,
    t =>
      t.jsonExample.name === pipeline?.triggerSpec?.name &&
      t.jsonExample.type === pipeline?.triggerSpec?.type
  );

  const mapValueSchema = (values = {}, schema = []) => {
    const keys = Object.keys(values);
    return keys.reduce((acc, current) => {
      const prop = schema.find(x => x.property === current);
      if (prop) {
        acc.push({
          label: prop.label,
          value: JSON.stringify(values[current])
        });
        return acc;
      }
      return acc;
    }, []);
  };
  const triggerSource = mapValueSchema(
    pipeline?.triggerSpec,
    head(trigger?.jsonSchema)?.schema
  );

  const deployType = props.showPromote
    ? 'promote'
    : pipelineRedeploy
    ? 'redeploy'
    : 'deploy';

  const cronFlagMinutes =
    treatments[RUN_CORE_ENGINE_DEPLOY_KEDA].treatment === 'on'
      ? JSON.parse(treatments[RUN_CORE_ENGINE_DEPLOY_KEDA].config || '{}')
          .targetInterval
      : null;

  const [disableAutoScalingSwitch, setDisableAutoScalingSwitch] = useState(
    () =>
      !isDefaultAutoScaling({
        cronExpression: pipeline?.triggerSpec?.cronExpression,
        cronFlagMinutes
      }) && pipeline?.triggerSpec?.type === 'scheduler'
  );

  const form = useFormik({
    initialValues: {
      isPipelineEngine: true,
      isReplicas: false,
      pipeline: { value: '', label: '' },
      version: { value: '', label: '' },
      replicasDeploy: { value: '', label: '' },
      projectName: '',
      configuration: null,
      replicas: 1,
      consumer: sliderProps.max,
      minReplicas: 0,
      isAutoScaling: true
    },
    validationSchema: PipelineSchema,
    onSubmit: async values => {
      const config = find(
        configurations,
        item => normalizeSize(item.name) === values?.configuration
      );
      const size = normalizeSize(config?.name);

      const environment = props.showPromote
        ? values.environment?.value
        : props.environment;

      const newDeploy = {
        configuration: {
          id: config?.id,
          size,
          consumers: values?.consumer
        },
        pipelineId: values?.version?.value,
        force: !!configurationLastDeploy,
        replicas: values?.replicas,
        minReplicas: values?.isAutoScaling
          ? values?.minReplicas
          : values?.replicas,
        environment,
        realm: props?.realm,
        replicaInstanceName: values?.replicasDeploy?.value,
        type: deployType,
        isPipelineEngine: !isPipelineEngine || values?.isPipelineEngine
      };
      const pipelineLastVersion = find(
        pipeline?.versionMinor,
        item => item.id === values?.version?.value
      );

      const licenseStatus = await apiLicenseStatus.fetch({
        environment,
        realm: props.realm,
        replicas: values?.replicas,
        pipelineId: values?.version?.value,
        size
      });

      const objViewDeploy = createObjectViewDeploy({
        pipeline: {
          ...deployment?.pipeline,
          ...pipelineLastVersion,
          triggerSpec: {
            ...pipelineLastVersion,
            ...pipeline.triggerSpec
          }
        },
        activeConfiguration: {
          ...config,
          replicaCount: values?.replicas,
          minReplicaCount: values?.isAutoScaling
            ? values?.minReplicas
            : values?.replicas
        },
        deploymentStatus: deployment?.deploymentStatus,
        licenses: pipeline?.licenses,
        replicas: values?.replicas,
        minReplicas: values?.isAutoScaling
          ? values?.minReplicas
          : values?.replicas,
        startTime: moment().valueOf(),
        force: !!configurationLastDeploy,
        licenseTotal: pipeline?.licenseTotal,
        parameterizedReplica: values?.replicasDeploy?.value,
        consumers: values?.consumer,
        licenseStatus
      });

      if (props.showPromote) {
        setNewDeployment({
          deployment: {
            ...objViewDeploy,
            newEnvironment: environment
          }
        });
        setOpenPromote(true);
      } else {
        setNewDeploy({
          ...newDeploy,
          environment,
          deployment: objViewDeploy,
          texts: texts[deployType].modalConfirmation,
          isRedeploy: pipelineRedeploy,
          projectId,
          projectName: values?.projectName,
          datatestidText: 'button-confirm-redeploy'
        });
        setShowConfirmDialog(true);
      }
    }
  });

  useEffect(() => {
    form.validateForm(form.values);
  }, [form.values]);

  const setConfigAndReplicas = async ({
    pipelineName,
    versionMajor,
    replicaName
  }) => {
    try {
      const { data } = await apiRuntime.findByNameAndVersionMajor({
        realm: props.realm,
        environment: props.environment,
        pipelineName: normalizeNamePipeline(pipelineName, replicaName),
        versionMajor
      });
      setConfigurationLastDeploy(data?.deploymentByNameAndVersionMajor);
      if (data?.deploymentByNameAndVersionMajor) {
        setSliderProps({
          max: data.deploymentByNameAndVersionMajor.activeConfiguration
            ?.consumers
        });

        if (
          isAutoScalingDeploy({
            minReplicaCount:
              data.deploymentByNameAndVersionMajor.activeConfiguration
                ?.minReplicaCount,
            replicaCount:
              data.deploymentByNameAndVersionMajor.activeConfiguration
                .replicaCount
          })
        ) {
          form.setFieldValue(
            'minReplicas',
            data.deploymentByNameAndVersionMajor.activeConfiguration
              ?.minReplicaCount
          );
          form.setFieldValue('isAutoScaling', true);
        } else {
          form.setFieldValue('isAutoScaling', false);
        }
        form.setFieldValue(
          'replicas',
          data.deploymentByNameAndVersionMajor.activeConfiguration?.replicaCount
        );
        form.setFieldValue(
          'configuration',
          normalizeSize(
            data.deploymentByNameAndVersionMajor.activeConfiguration?.name
          )
        );
        form.setFieldValue(
          'consumer',
          data.deploymentByNameAndVersionMajor.activeConfiguration
            ?.actualConsumers
        );
      }
    } catch (e) {
      setConfigurationLastDeploy(null);
    }
  };

  const addConfigurations = async ({ pipelineVersionId }) => {
    setConfigurations(null);
    try {
      const { data } = await apiConfiguration.find({
        pipelineVersionId,
        realm: props.realm,
        environment: props.environment
      });
      setConfigurations(data?.configurations);
    } catch (e) {
      setConfigurations(null);
    }
  };

  const checkLicense = async variant => {
    if (variant?.size) {
      const size = normalizeSize(variant?.size);
      try {
        const { data } = await apiRuntime.checkLicense({
          ...variant,
          size,
          realm: props.realm,
          environment: props.environment
        });
        setValidationLicense(data?.checkLicense?.analysis);
      } catch (e) {
        setValidationLicense([]);
      }
    }
  };

  const validatePipeline = async variant => {
    try {
      const data = await apiPipeline.validatePipeline({
        ...variant,
        realm: props.realm
      });

      setValidationPipeline(data?.analysis);
    } catch (e) {
      setValidationPipeline([]);
    }
  };

  const setMultiInstances = async variant => {
    try {
      if (variant?.replicaName) {
        const { data } = await apiPipeline.multiInstance({
          ...variant,
          realm: props.realm,
          environment: props.environment
        });
        setPipelineInstences(data?.replicaInstance);
      }
    } catch (e) {
      setPipelineInstences([]);
    }
  };

  const closePromote = () => {
    setOpenPromote(false);
  };

  const pipelineToPromoteConfirm = async deploymentData => {
    setLoadingPromote(true);
    const isPipelineEngineForm = form.values.isPipelineEngine;
    const newPayload = {
      configuration: {
        consumers: deploymentData.activeConfiguration.actualConsumers,
        id: deploymentData.activeConfiguration.id,
        size: normalizeSize(deploymentData.activeConfiguration.name)
      },
      environment: deploymentData.newEnvironment,
      force: true,
      pipelineId: deploymentData.pipeline.id,
      replicaInstanceName: deploymentData.replicaName,
      realm: props.realm,
      replicas: deploymentData.replicas,
      minReplicas: deploymentData.minReplicas,
      isPipelineEngine: !isPipelineEngine || isPipelineEngineForm
    };

    if (form.values.deletePipe) {
      props.remove({
        environment: deployment?.activeConfiguration?.environment?.name,
        realm: props.realm,
        deployment: deployment.id
      });
    }

    props.createDeploy(newPayload);

    const { project } = await api.pipelineProjectName({
      id: deployment.pipeline.id,
      realm: props?.realm
    });

    props.changeEnvironment(deploymentData.newEnvironment);
    setOpenPromote(false);
    setLoadingPromote(false);
    props.close();

    if (isNewRoutesParams) {
      setSearchParams({
        pipelineName: searchParams.get('pipelineName'),
        projectId: project.id
      });
    }
  };

  const actions = {
    onAdvancedSettingsToggle: handleToggle,
    loadPipelines: async (search, _, { searchPage, page, previousSearch }) => {
      if (isNewFetchPipeline) {
        return new Promise((resolve, reject) =>
          debounce(async () => {
            try {
              const request = await api.fetch({
                realm: props?.realm,
                size: 10,
                search: {
                  name: search,
                  page: search
                    ? previousSearch == search
                      ? ++searchPage
                      : 0
                    : ++page
                }
              });
              const options = normalizePipelines(
                request?.data?.searchPipelines?.content
              );

              const hasMore = !request?.data?.searchPipelines?.last;

              resolve({
                options,
                hasMore,
                additional: {
                  page: !search ? request?.data?.searchPipelines?.number : page,
                  searchPage: search
                    ? previousSearch == search
                      ? request?.data?.searchPipelines?.number
                      : 0
                    : -1,
                  previousSearch: search
                }
              });
            } catch (error) {
              reject();
            }
          }, 1000)()
        );
      }

      try {
        const request = await api.fetch({
          realm: props?.realm,
          size: 10,
          search: {
            name: search,
            page: search
              ? previousSearch == search
                ? ++searchPage
                : 0
              : ++page
          }
        });

        const options = normalizePipelines(
          request?.data?.searchPipelines?.content
        );

        const hasMore = !request?.data?.searchPipelines?.last;

        return {
          options,
          hasMore,
          additional: {
            page: !search ? request?.data?.searchPipelines?.number : page,
            searchPage: search
              ? previousSearch == search
                ? request?.data?.searchPipelines?.number
                : 0
              : -1,
            previousSearch: search
          }
        };
      } catch (e) {
        // console.log(e);
      }
    },
    onChangePipeline: async optionPipline => {
      try {
        form.setFieldValue('pipeline', optionPipline);

        form.setFieldValue('configuration', null);

        form.setFieldValue('version', { label: '', value: '' });

        form.setFieldValue('projectName', '');

        setConfigurationLastDeploy(null);

        setConfigurations([]);

        setPipelineInstences([]);

        const current = await apiRuntime.pipelineVersions({
          realm: props?.realm,
          environment: props?.environment,
          id: optionPipline?.value
        });

        const _disableAutoScalingSwitch =
          !isDefaultAutoScaling({
            cronExpression: current?.triggerSpec?.cronExpression,
            cronFlagMinutes
          }) && current?.triggerSpec?.type === 'scheduler';
        setDisableAutoScalingSwitch(_disableAutoScalingSwitch);

        form.setFieldValue('isAutoScaling', !_disableAutoScalingSwitch);

        setMultiInstances({
          replicaName: current?.parameterizedReplica
        });

        form.setFieldValue('isReplicas', !!current?.parameterizedReplica);
        let pipelineLastVersion = maxBy(
          filter(current?.versionMinor, item => !item.disabled),
          item => item.versionMinor
        );

        if (pipelineRedeploy) {
          pipelineLastVersion = pipelineRedeploy;
        }

        validatePipeline({
          pipelineId: pipelineLastVersion?.id,
          environment: props.environment
        });

        await addConfigurations({
          pipelineVersionId: pipelineLastVersion?.id
        });

        const { project } = await api.pipelineProjectName({
          id: current.id,
          realm: props?.realm
        });

        setProjectId(project?.id);
        form.setFieldValue('projectName', project?.name);

        form.setFieldValue('version', {
          value: pipelineLastVersion?.id,
          label: `${pipelineLastVersion?.versionMajor}.${pipelineLastVersion?.versionMinor}`
        });

        form.setFieldValue(
          'isPipelineEngine',
          pipelineRedeploy ? pipelineLastVersion?.isPipelineEngine : true
        );

        if (props.showPromote) {
          setPipeline({
            ...props.deploymentSelected.pipeline,
            triggerSpec: current.triggerSpec
          });

          setSliderProps({
            max: props.deploymentSelected.activeConfiguration?.consumers
          });
          if (
            isAutoScalingDeploy({
              minReplicaCount:
                props.deploymentSelected?.activeConfiguration?.minReplicaCount,
              replicaCount:
                props.deploymentSelected.activeConfiguration.replicaCount
            })
          ) {
            form.setFieldValue(
              'minReplicas',
              props.deploymentSelected.activeConfiguration?.minReplicaCount
            );
            form.setFieldValue('isAutoScaling', true);
          } else {
            form.setFieldValue('isAutoScaling', false);
          }
          form.setFieldValue(
            'replicas',
            props.deploymentSelected.activeConfiguration?.replicaCount
          );
          form.setFieldValue(
            'minReplicas',
            props.deploymentSelected.activeConfiguration?.minReplicaCount || 0
          );

          form.setFieldValue(
            'configuration',
            normalizeSize(props.deploymentSelected.activeConfiguration?.name)
          );
          form.setFieldValue(
            'consumer',
            props.deploymentSelected.activeConfiguration?.actualConsumers
          );

          form.setFieldValue('environment', {
            value: props.environment,
            label: props.environment
          });

          form.setFieldValue('deletePipe', false);

          setPipeline(current);
          return;
        }

        setConfigAndReplicas({
          pipelineName: pipelineLastVersion?.name,
          versionMajor: pipelineLastVersion?.versionMajor
        });

        setPipeline(current);
      } catch (e) {
        setPipeline(null);
      }
    },
    onChangePipelineVersionEngine: engineVersion => {
      form.setFieldValue('isPipelineEngine', engineVersion.value);
    },
    onChangePipelineVersion: async optionPipelineVersion => {
      form.setFieldValue('version', optionPipelineVersion);

      setConfigurationLastDeploy(null);

      if (!props.showPromote) {
        form.setFieldValue('configuration', null);
      }

      if (
        isAutoScalingDeploy({
          minReplicaCount:
            props.deploymentSelected?.activeConfiguration.minReplicaCount,
          replicaCount:
            props.deploymentSelected?.activeConfiguration.replicaCount
        })
      ) {
        form.setFieldValue(
          'minReplicas',
          props.deploymentSelected?.activeConfiguration.minReplicaCount
        );
        form.setFieldValue('isAutoScaling', true);
      } else {
        form.setFieldValue('isAutoScaling', false);
      }

      form.setFieldValue(
        'replicas',
        props.deploymentSelected?.activeConfiguration.replicaCount
      );

      form.setFieldValue('projectName', '');

      setConfigurations([]);

      setValidationPipeline([]);

      const pipelineLastVersion = find(
        pipeline?.versionMinor,
        item => item.id === optionPipelineVersion.value
      );

      form.setFieldValue(
        'isReplicas',
        !!pipelineLastVersion?.parameterizedReplica
      );

      addConfigurations({
        pipelineVersionId: optionPipelineVersion.value
      });
      if (pipelineLastVersion?.id) {
        await validatePipeline({
          pipelineId: pipelineLastVersion?.id,
          environment: props.environment
        });
      }
      setMultiInstances({
        replicaName: pipelineLastVersion?.parameterizedReplica
      });
      if (pipelineLastVersion?.name && !props.showPromote) {
        setConfigAndReplicas({
          pipelineName: pipelineLastVersion?.name,
          versionMajor: pipelineLastVersion?.versionMajor
        });
      }

      const { project } = await api.pipelineProjectName({
        id: pipeline.id,
        realm: props?.realm,
        pipelineMajorVersion: parseInt(optionPipelineVersion.label, 10)
      });

      form.setFieldValue('projectName', project?.name);
    },
    onChangeConfiguration: (
      _,
      { pipelineId, replicas, value, replicasDeploy }
    ) => {
      form.setFieldValue('configuration', value);
      const config = find(
        configurations,
        item => normalizeSize(item.name) === value
      );

      checkLicense({
        size: config?.name,
        pipelineId,
        replicas,
        replicaInstanceName: replicasDeploy.value
      });

      setSliderProps({
        max: config.consumers
      });

      if (deployType === 'deploy') {
        if (!form.touched.replicas) {
          const _replicas = () => {
            if (pipeline.triggerSpec.type !== 'scheduler') {
              return 1;
            }
            if (!pipeline.triggerSpec.concurrentScheduling) {
              return 1;
            }
            if (
              !isDefaultAutoScaling({
                cronExpression: pipeline?.triggerSpec?.cronExpression,
                cronFlagMinutes
              }) &&
              pipeline?.triggerSpec?.type === 'scheduler'
            ) {
              return 1;
            }

            return 2;
          };

          form.setFieldValue('replicas', _replicas());
        }
        if (!form.touched.consumer) {
          const _consumers = () => {
            if (
              isDefaultAutoScaling({
                cronExpression: pipeline?.triggerSpec?.cronExpression,
                cronFlagMinutes
              }) &&
              pipeline?.triggerSpec?.type === 'scheduler'
            ) {
              return 1;
            }
            if (!pipeline.triggerSpec.concurrentScheduling) {
              return 1;
            }
            return 2;
          };
          form.setFieldValue('consumer', _consumers());
        }
      }
      if (form.touched.consumer && form.values.consumer > config.consumers) {
        form.setFieldValue('consumer', config.consumers);
        return;
      }
      if (!form.touched.consumer && deployType !== 'deploy') {
        form.setFieldValue('consumer', config.consumers);
      }
    },
    onChangeConsumer: async (_, value) => {
      form.setFieldValue('consumer', value);
      form.setFieldTouched('consumer', true);
    },
    onChangeReplicasDeploy: async ({
      pipelineId,
      configurationId,
      replicas,
      replicasDeploy
    }) => {
      form.setFieldValue('replicasDeploy', replicasDeploy);
      form.setFieldValue('configuration', null);

      const pipelineLastVersion = maxBy(
        filter(pipeline?.versionMinor, item => !item.disabled),
        item => item?.versionMinor
      );
      await addConfigurations({
        pipelineVersionId: pipelineLastVersion?.id
      });

      const config = find(
        configurations,
        item => normalizeSize(item.name) === configurationId
      );

      checkLicense({
        pipelineId,
        size: config?.name,
        replicas,
        replicaInstanceName: replicasDeploy.value
      });

      setConfigAndReplicas({
        pipelineName: pipelineLastVersion?.name,
        versionMajor: pipelineLastVersion?.versionMajor,
        replicaName: replicasDeploy.value
      });
    },
    onChangeMinReplicas: ({ value, maxReplicas }) => {
      const currentReplicas = form.values.replicas;
      const currentMinReplicas = form.values.minReplicas;

      if (value >= currentMinReplicas) {
        form.setFieldValue('replicas', maxReplicas + 1);
      }
      if (value > currentReplicas) {
        form.setFieldValue('replicas', value);
      }
      form.setFieldValue('minReplicas', value);
    },
    onChangeReplicas: ({
      pipelineId,
      configurationId,
      replicasDeploy,
      value
    }) => {
      form.setFieldValue('replicas', value);
      form.setFieldTouched('replicas', true);
      const config = find(
        configurations,
        item => normalizeSize(item.name) === configurationId
      );
      checkLicense({
        pipelineId,
        size: config?.name,
        replicas: value,
        replicaInstanceName: replicasDeploy.value
      });
    },
    onChangeDeletePipe: value => {
      form.setFieldValue('deletePipe', value);
    },
    onChangeEnvironment: async ({ newEnv, replicas, size }) => {
      const licenseStatus = await apiLicenseStatus.fetch({
        environment: newEnv.value,
        realm: props.realm,
        replicas,
        pipelineId: pipeline.id,
        size
      });
      form.setFieldValue('environment', newEnv);
      setPipeline({
        ...pipeline,
        licenseStatus
      });
    },
    onChangeIsAutoScaling: ({ value }) => {
      form.setFieldValue('isAutoScaling', value);

      if (deployType === 'deploy') {
        if (!form.touched.replicas) {
          const _replicas = () => {
            if (pipeline.triggerSpec.type !== 'scheduler') {
              return 1;
            }
            if (!pipeline.triggerSpec.concurrentScheduling) {
              return 1;
            }
            if (!value) return 1;

            return 2;
          };

          form.setFieldValue('replicas', _replicas());
        }
        if (!form.touched.consumer) {
          const _consumers = () => {
            if (!pipeline.triggerSpec.concurrentScheduling) {
              return 1;
            }
            if (value) return 1;

            return 2;
          };
          form.setFieldValue('consumer', _consumers());
        }
      }
    }
  };
  useEffect(() => {
    if (!deployment) return;
    const initial = async () => {
      await actions.onChangePipeline(createObjectOption(deployment));
      await actions.onChangePipelineVersion(
        createObjectPipelineVersion(deployment?.pipeline)
      );
    };

    initial();
    return () => {
      dispatch.runtime.resetDeployment();
    };
  }, [deployment]);

  useEffect(() => {
    async function getPipelineEngine() {
      const { data } = await apiRuntime.deploymentPipelineEngineConfiguration({
        realm: props.realm,
        environment: props.environment
      });

      form.setFieldValue(
        'isPipelineEngine',
        data?.deploymentPipelineEngineConfiguration
      );
    }

    getPipelineEngine();
  }, []);

  useEffect(() => {
    const setPipeline = async () => {
      const pipelineValue = {
        label: pipelineRedeploy.name,
        value: pipelineRedeploy.id
      };
      form.setFieldValue('isPipelineEngine', pipelineRedeploy.isPipelineEngine);
      await actions.onChangePipeline(pipelineValue);
    };

    if (pipelineRedeploy) {
      setPipeline();
    }
  }, [pipelineRedeploy]);

  const versionMinor = map(pipeline?.versionMinor, item => ({
    label: `${item?.versionMajor}.${item?.versionMinor}`,
    value: item?.id
  }));

  const optionVersionEngine = [
    {
      label: i18n.t('label.deprecated_V1'),
      value: false
    },
    {
      label: i18n.t('label.latest_V2'),
      value: true
    }
  ];

  const replicasDeploy = map(pipelineInstances, item => ({
    label: item.name,
    value: item.name
  }));

  const pipelineValidationError = useMemo(
    () =>
      validationPipeline?.filter?.(a => {
        const errors = a?.details.filter(detail => detail?.level == 'ERROR');
        return errors.length;
      }),
    [validationPipeline]
  );

  const closeDialog = () => {
    setShowConfirmDialog(false);
  };

  const confirmDeploy = value => {
    setShowConfirmDialog(false);

    props.find({
      realm: props.realm,
      environment: value.environment,
      search: {
        projectId: value.projectId,
        projectName: value?.projectName
      }
    });
    props.onCloseClick();

    if (isNewRoutesParams) {
      setSearchParams({
        pipelineName: searchParams.get('pipelineName'),
        projectId: value.projectId
      });
    }
  };

  return {
    ...props,
    ...actions,
    versionMinor,
    optionVersionEngine,
    configurations,
    sliderProps,
    validationPipeline,
    validationLicense,
    configurationLastDeploy,
    replicasDeploy,
    trigger,
    triggers,
    triggerSource,
    pipeline,
    pipelineValidationError,
    licenses: pipeline?.licenseTotal,
    form,
    deployment: {
      ...deployment,
      licenseStatus: deployment?.pipeline?.licenseStatus
    },
    openPromote,
    closePromote,
    newDeployment,
    pipelineToPromoteConfirm,
    loadingPromote,
    texts: texts[deployType],
    showConfirmDialog,
    closeDialog,
    newDeploy,
    confirmDeploy,
    disableAutoScalingSwitch,
    allowConcurrentScheduling: pipeline?.triggerSpec?.concurrentScheduling,
    isOpenAdvancedSettings,
    showInactiveTriggersExperience
  };
});

export default enhancer;
