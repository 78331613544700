import styled from 'styled-components';

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  font-family: ${props => props.theme.typography.fontFamily};
`;

type ChatContentProps = {
  textFieldHeight: number;
};

export const ChatContent = styled.div<ChatContentProps>`
  overflow-y: auto;
  padding: ${props => props.theme.spacings.mega} 0;
  box-sizing: border-box;
  height: calc(100vh - 84px - ${props => props.textFieldHeight}px);
`;

export const ChatActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${props => props.theme.spacings.kilo};
  box-sizing: border-box;
  align-items: end;
`;

export const ChatSendButton = styled.button`
  padding: ${props => props.theme.spacings.kilo};
  border: 1px solid ${props => props.theme.colors.gra100};
  border-radius: 8px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  cursor: pointer;
`;

export const ChatFieldWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${props => props.theme.spacings.kilo};
`;

export const ResizableTextarea = styled.textarea`
  width: 100%;
  min-height: 40px;
  max-height: 300px;
  padding: ${props => props.theme.spacings.byte};
  border: 1px solid ${props => props.theme.colors.gra100};
  border-radius: 4px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  resize: vertical;
  overflow-y: auto;
  box-sizing: border-box;
  outline: none;
`;

export const ChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacings.kilo};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.gra100};
  margin: ${props => props.theme.spacings.kilo} 0;
`;

export const Title = styled.h1`
  font-size: 18px;
  margin-bottom: ${props => props.theme.spacings.mega};
  font-weight: 500;
`;

export const Description = styled.p`
  font-size: 16px;
  margin-bottom: ${props => props.theme.spacings.kilo};
`;

export const MessagesWrapper = styled.div``;
