import { useSelector } from 'react-redux';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export const useIsAutoscaling = () => {
  const { license } = useSelector<
    StoreRootState,
    Pick<StoreRootState, 'license'>
  >(state => state);

  const licensePlanName = 'CONSUMPTIONBASEDMODEL';

  const planNameWithoutSpaces = license?.license?.planName
    .replace(/\s+/g, '')
    .toUpperCase();

  return planNameWithoutSpaces === licensePlanName;
};
