import Immutable from 'seamless-immutable';

// Locals
import { insights } from '~/api';
import getEnvironmentsToPathName from '~/common/helpers/getEnvironmentsToPathName';
import getRealmToPathName from '~/common/helpers/getRealmToPathName';

const initialState = Immutable({
  loading: true,
  loadingMetrics: false,
  realm: getRealmToPathName(window.location.pathname),
  data: null,
  error: false,
  environment: getEnvironmentsToPathName(window.location.pathname),
  pipeline: '',
  pipelineName: '',
  ref: null,
});

const insightsModel = {
  name: 'insights',
  state: initialState,
  reducers: {
    setLoading(state, payload) {
      return state.merge({
        loading: payload,
      });
    },
    setPipeline(state, payload) {
      return state.merge({
        pipeline: payload,
      });
    },
    setRef(state, payload) {
      return state.merge({
        ref: payload,
      });
    },
    setData(state, payload) {
      return state.merge({
        data: payload,
      });
    },
    setError(state, payload) {
      return state.merge({
        error: payload,
      });
    },
    setPipelineName(state, payload) {
      return state.merge({
        pipelineName: payload,
      });
    },
  },
  effects: (dispatch) => ({
    async find({ environment, realm, dateTo, dateFrom, pipelineName, pipelineMajorVersion, pipelineMinorVersion }) {
      try {
        dispatch.insights.setLoading(true);

        const request = await insights.find({
          dateTo, dateFrom, environment, realm, pipelineName, pipelineMajorVersion, pipelineMinorVersion
        });

        dispatch.insights.setData(request.data.insights);
        dispatch.insights.setLoading(false);
      } catch (e) {
        dispatch.insights.setError(true);
        dispatch.insights.setLoading(false);
      }
    },
  }),
};

export default insightsModel;
