import overEvery from 'lodash/overEvery';
import { ReactNode } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useDocumentTitle from '~/common/hooks/useDocumentTitle';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import asyncComponent from '~/components/AsyncComponent';
import { ConfirmDialogProvider } from '~/components/ConfirmDialogV2/contexts/confirmDialogProvider';
import BuildRoutes, { buildSettingsRoutes } from '~/scenes/Build/scenes';
import { ConnectivityRoutes } from '~/scenes/Connectivity/scenes';
import { RouteComponent as RouteComponentV2 } from '~/scenes/Governance/scenes/Login/components';
import LimitOfUse from '~/scenes/GrowthAndLicense/scenes/LimitOfUse';
import {
  FormLogin,
  RouteComponent
} from '~/scenes/IdentityAndAccess/scenes/Login/components';
import { MonitorRoutes } from '~/scenes/Monitor';
import Accounts from '~/scenes/Others/scenes/Accounts';
import Settings from '~/scenes/Others/scenes/App/Settings';
import { RoutesRun } from '~/scenes/Runtime/scenes';

const Credentials = asyncComponent(
  () => import('~/scenes/Monitor/scenes/Credentials')
);

const AlertsConfig = asyncComponent(
  () => import('~/scenes/Monitor/scenes/AlertsConfig')
);

const IdPSettings = asyncComponent(
  () => import('~/scenes/Governance/scenes/IdPSettings')
);

const AuthenticationRules = asyncComponent(
  () => import('~/scenes/Governance/scenes/AuthenticationRules')
);

const ApiKeys = asyncComponent(
  () => import('~/scenes/Components/scenes/ApiKeys')
);
const ApiKeysV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/ApiKeys')
);
const Audit = asyncComponent(() => import('~/scenes/Governance/scenes/Audit'));

const Auth = asyncComponent(
  () => import('~/scenes/IdentityAndAccess/scenes/Auth')
);
const UserBeta = asyncComponent(
  () => import('~/scenes/IdentityAndAccess/scenes/BetaUser')
);
const UsersV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/Users')
);
const Globals = asyncComponent(() => import('~/scenes/Others/scenes/Globals'));
const GlobalsV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/Globals')
);

const Groups = asyncComponent(
  () => import('~/scenes/IdentityAndAccess/scenes/Groups')
);
const GroupsV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/Groups')
);
const GroupsIntegration = asyncComponent(
  () => import('~/scenes/IdentityAndAccess/scenes/GroupsIntegration')
);
const GroupsIntegrationV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/GroupsIntegration')
);
const Login = asyncComponent(
  () => import('~/scenes/IdentityAndAccess/scenes/Login')
);
const LoginV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/Login')
);

const MultiInstance = asyncComponent(
  () => import('~/scenes/Others/scenes/MultiInstance')
);
const MultiInstanceV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/MultiInstance')
);
const OAuthProviders = asyncComponent(
  () => import('~/scenes/Governance/scenes/OAuthProviders')
);
const Relations = asyncComponent(
  () => import('~/scenes/Components/scenes/Relations')
);
const Roles = asyncComponent(
  () => import('~/scenes/IdentityAndAccess/scenes/Roles')
);
const RolesV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/Roles')
);
const SuccessOauth2 = asyncComponent(
  () => import('~/scenes/Others/scenes/SuccessOauth2')
);
const User = asyncComponent(
  () => import('~/scenes/IdentityAndAccess/scenes/User')
);
const Licensing = asyncComponent(
  () => import('~/scenes/GrowthAndLicense/scenes/Licensing')
);
const LicensingV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/LicenseUsage')
);

const Playground = asyncComponent(
  () => import('~/scenes/Components/scenes/Playground')
);

const LimitDetails = asyncComponent(
  () => import('~/scenes/GrowthAndLicense/scenes/LimitDetails')
);

const AccountsV2 = asyncComponent(
  () => import('~/scenes/Governance/scenes/Accounts')
);

const Policies = asyncComponent(
  () => import('~/scenes/Governance/scenes/Policies')
);

const TokenCtl = asyncComponent(
  () => import('~/scenes/Governance/scenes/TokenCtl')
);

type CustomRouteObject = RouteObject & {
  name?: string;
  title?: string;
  active?: boolean;
  key?: string;
  children?: CustomRouteObject[] | undefined;
};

type RouteValidator = (route: CustomRouteObject) => boolean;

type PageWrapperProps = {
  children?: ReactNode;
  title?: string;
};

const RouteTitleWrapper = ({ children, title }: PageWrapperProps) => {
  useDocumentTitle({ title });
  return children;
};

const traverseRoutes = (
  routes: CustomRouteObject[],
  validator: RouteValidator
): CustomRouteObject[] =>
  routes.reduce((accRoutes: CustomRouteObject[], route: CustomRouteObject) => {
    const isValid = validator(route);

    if (!isValid) return accRoutes;

    const children = route.children
      ? traverseRoutes(route.children, validator)
      : null;

    const element =
      route.element && route.title ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <RouteTitleWrapper title={route.title}>
          {route.element}
        </RouteTitleWrapper>
      ) : (
        route.element
      );

    const routeWithTraversedChildren = {
      ...route,
      element,
      children
    } as CustomRouteObject;

    return accRoutes.concat(routeWithTraversedChildren);
  }, []);

type RoutesProps = {
  hiddenPages: string[];
};

const {
  SETTINGS_GLOBALS_V2,
  PLAYGROUND,
  GOVERNANCE_DS_MIGRATION_LICENSE_USAGE,
  GOVERNANCE_DS_MIGRATION_ROLES,
  GOVERNANCE_DS_MIGRATION_USERS,
  GOVERNANCE_DS_MIGRATION_GROUPS,
  SETTINGS_ACCOUNTS_V2,
  SETTINGS_ACCOUNTS_NEW_DS,
  GOVERNANCE_DS_MIGRATION_CONSUMER_APIS,
  GOVERNANCE_DS_MIGRATION_MULTI_INSTANCE,
  GOVERNANCE_DS_MIGRATION_LOGIN_PAGES
} = featureFlagConstants;

const Routes = ({ hiddenPages }: RoutesProps) => {
  const { treatments } = useFeatureFlag([
    SETTINGS_GLOBALS_V2,
    PLAYGROUND,
    GOVERNANCE_DS_MIGRATION_LICENSE_USAGE,
    GOVERNANCE_DS_MIGRATION_ROLES,
    GOVERNANCE_DS_MIGRATION_USERS,
    GOVERNANCE_DS_MIGRATION_GROUPS,
    GOVERNANCE_DS_MIGRATION_CONSUMER_APIS,
    GOVERNANCE_DS_MIGRATION_MULTI_INSTANCE,
    SETTINGS_ACCOUNTS_V2,
    SETTINGS_ACCOUNTS_NEW_DS,
    GOVERNANCE_DS_MIGRATION_LOGIN_PAGES
  ]);
  const showMultiInstanceV2 =
    treatments[GOVERNANCE_DS_MIGRATION_MULTI_INSTANCE].treatment === 'on';
  const showApiKeysV2 =
    treatments[GOVERNANCE_DS_MIGRATION_CONSUMER_APIS].treatment === 'on';
  const showGlobalsV2 = treatments[SETTINGS_GLOBALS_V2].treatment === 'on';
  const showPlayground = treatments[PLAYGROUND].treatment === 'on';
  const showLicenseUsageV2 =
    treatments[GOVERNANCE_DS_MIGRATION_LICENSE_USAGE].treatment === 'on';
  const showRolesV2 =
    treatments[GOVERNANCE_DS_MIGRATION_ROLES].treatment === 'on';
  const showGroupsV2 =
    treatments[GOVERNANCE_DS_MIGRATION_GROUPS].treatment === 'on';
  const showUsersV2 =
    treatments[GOVERNANCE_DS_MIGRATION_USERS].treatment === 'on';
  const showAccountsV2 =
    treatments[SETTINGS_ACCOUNTS_NEW_DS].treatment === 'on';
  const showLoginV2 =
    treatments[GOVERNANCE_DS_MIGRATION_LOGIN_PAGES].treatment === 'on';
  const routesConfig: CustomRouteObject[] = [
    {
      path: '/login',
      element: showLoginV2 ? <LoginV2 /> : <Login />,
      title: 'Login',
      children: [
        {
          index: true,
          path: '',
          title: i18n.t('label.login_tab'),
          element: showLoginV2 ? <RouteComponentV2 /> : <RouteComponent />
        },
        {
          path: ':actionId',
          title: i18n.t('label.login_tab'),
          element: showLoginV2 ? <RouteComponentV2 /> : <RouteComponent />
        },
        {
          path: ':actionId/:changePasswordId',
          title: i18n.t('label.change_password_tab'),
          element: showLoginV2 ? <RouteComponentV2 /> : <RouteComponent />
        },
        {
          path: '*',
          title: i18n.t('label.login_tab'),
          element: showLoginV2 ? <RouteComponentV2 /> : <FormLogin />
        }
      ]
    },
    { path: '/oauth', title: i18n.t('label.oauth_tab'), element: <Auth /> },
    {
      path: '/oauth/error',
      title: i18n.t('label.oauth_tab'),
      element: <SuccessOauth2 isError />
    },
    {
      path: '/oauth2/success',
      title: i18n.t('label.oauth_tab'),
      element: <SuccessOauth2 />
    },
    ...BuildRoutes(),
    ...RoutesRun(),
    ...MonitorRoutes(),
    ...ConnectivityRoutes(),
    {
      path: '/:realm/settings',
      element: <Settings />,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      children: [
        {
          path: 'policies/:category',
          name: 'policies',
          title: 'Policies',
          element: <Policies />,
          children: [
            {
              path: ':policyName',
              title: 'policy',
              element: <Policies />
            },
            {
              path: 'compliance/pipelines/:environment',
              title: 'policy pipelines issue',
              element: <Policies />
            }
          ]
        },
        {
          path: 'beta/user',
          name: 'settings-user-beta',
          title: i18n.t('label.user_tab'),
          element: showUsersV2 ? (
            <ConfirmDialogProvider>
              <UsersV2 />
            </ConfirmDialogProvider>
          ) : (
            <UserBeta />
          )
        },
        {
          path: 'groups/list',
          name: 'settings-groups-list',
          title: i18n.t('label.groups_tab'),
          element: (
            <ConfirmDialogProvider>
              {showGroupsV2 ? <GroupsV2 /> : <Groups />}
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'groups/integrations',
          name: 'settings-groups-integrations',
          title: i18n.t('label.group_integrations_tab'),
          element: (
            <ConfirmDialogProvider>
              {showGroupsV2 ? <GroupsIntegrationV2 /> : <GroupsIntegration />}
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'roles',
          name: 'settings-roles',
          title: i18n.t('label.roles_tab'),
          element: showRolesV2 ? (
            <ConfirmDialogProvider>
              <RolesV2 />
            </ConfirmDialogProvider>
          ) : (
            <Roles />
          )
        },
        {
          path: 'user',
          name: 'settings-user',
          title: i18n.t('label.users_tab'),
          element: <User />
        },
        {
          path: 'accounts',
          name: 'settings-accounts',
          title: i18n.t('label.accounts_tab'),
          element: showAccountsV2 ? (
            <ConfirmDialogProvider>
              <AccountsV2 />
            </ConfirmDialogProvider>
          ) : (
            <Accounts />
          )
        },
        {
          path: 'oauthProviders',
          name: 'settings-oauth-providers',
          title: i18n.t('label.oauth_tab'),
          element: (
            <ConfirmDialogProvider>
              <OAuthProviders />
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'api-keys',
          name: 'settings-api-keys',
          title: i18n.t('label.consumers_api_keys_tab'),
          element: (
            <ConfirmDialogProvider>
              {showApiKeysV2 ? <ApiKeysV2 /> : <ApiKeys />}
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'relationship',
          name: 'settings-relationship',
          title: i18n.t('label.relationship_tab'),
          element: (
            <ConfirmDialogProvider>
              <Relations />
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'multiinstance',
          name: 'settings-multiinstance',
          title: i18n.t('label.multi_instance_tab'),
          element: (
            <ConfirmDialogProvider>
              {showMultiInstanceV2 ? <MultiInstanceV2 /> : <MultiInstance />}
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'globals',
          name: 'settings-globals',
          title: i18n.t('label.globals_tab'),
          element: showGlobalsV2 ? (
            <ConfirmDialogProvider>
              <GlobalsV2 />
            </ConfirmDialogProvider>
          ) : (
            <Globals />
          )
        },
        {
          path: 'playground',
          name: 'settings-playground',
          element: showPlayground ? <Playground /> : null
        },
        {
          path: 'audit',
          name: 'settings-audit',
          title: i18n.t('label.audit_tab'),
          element: (
            <ConfirmDialogProvider>
              <Audit />
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'ctl/token',
          name: 'settings-ctl-token',
          title: i18n.t('label.digibeeclt_token_tab'),
          element: (
            <ConfirmDialogProvider>
              <TokenCtl />
            </ConfirmDialogProvider>
          )
        },
        ...buildSettingsRoutes,
        {
          path: 'licensing',
          name: 'settings-licensing',
          title: i18n.t('label.license_usage_tab'),
          element: showLicenseUsageV2 ? <LicensingV2 /> : <Licensing />
        },
        {
          path: 'limits/global',
          name: 'settings-global-limits',
          title: i18n.t('label.global_capacity_tab'),
          element: <LimitOfUse isGlobal />,
          children: [
            {
              path: ':limit/environment/:environment',
              title: i18n.t('label.global_capacity_tab'),
              element: <LimitDetails />
            }
          ]
        },
        {
          path: 'limits/local',
          name: 'settings-local-limits',
          title: i18n.t('label.local_capacity_tab'),
          element: <LimitOfUse isGlobal={false} />,
          children: [
            {
              path: ':limit/environment/:environment',
              title: i18n.t('label.local_capacity_tab'),
              element: <LimitDetails />
            }
          ]
        },
        {
          path: 'identity-provider/authentication-rules',
          name: 'idp-authentication-rules',
          title: i18n.t('label.identity_provider_tab'),
          element: (
            <ConfirmDialogProvider>
              <AuthenticationRules />
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'identity-provider/idp-settings',
          name: 'idp-settings',
          title: i18n.t('label.identity_provider_tab'),
          element: (
            <ConfirmDialogProvider>
              <IdPSettings />
            </ConfirmDialogProvider>
          )
        },
        {
          path: 'alert/:environment',
          name: 'settings-alert',
          title: i18n.t('label.monitor_alerts_tab'),
          element: <AlertsConfig />
        },
        {
          path: 'credentials',
          name: 'settings-credentials',
          title: 'Credentials',
          element: <Credentials />
        }
      ]
    }
  ];

  const isShownPage: RouteValidator = (route: CustomRouteObject) =>
    route.name ? !hiddenPages.includes(route.name) : true;

  const activeAttribute: RouteValidator = (route: CustomRouteObject) =>
    typeof route.active === 'boolean' ? route.active : true;

  const routes = traverseRoutes(
    routesConfig,
    overEvery([isShownPage, activeAttribute])
  );

  const element = useRoutes(routes);

  return element;
};

export default Routes;
