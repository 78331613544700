import styled from 'styled-components';

const PANEL_SIZE = '400px';

export const GlobalPanelContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`;

type GlobalPanelWrapperProps = {
  open: boolean;
};

export const GlobalPanelWrapper = styled.div<GlobalPanelWrapperProps>`
  width: ${({ open }) => (open ? `calc(100% - ${PANEL_SIZE})` : '100%')};
  box-sizing: border-box;
`;

export const GlobalPanelContentWrapper = styled.div`
  width: ${PANEL_SIZE};
  height: 100vh;
  padding: ${props => props.theme.spacings.kilo};
  border-left: 1px solid ${props => props.theme.colors.gra100};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const GlobalPanelContent = styled.div`
  flex: 1
  box-sizing: border-box;
`;

export const PanelHeader = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${props => props.theme.colors.gra600};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.gra100};
  padding-bottom: ${props => props.theme.spacings.kilo};
  height: 32px;
  align-items: center;
`;

export const PanelTitle = styled.h3`
  font-size: 22px;
  font-weight: 500;
  display: flex;
  gap: ${props => props.theme.spacings.byte};
`;

export const PanelActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: ${props => props.theme.spacings.byte};
`;

export const PanelCloseAction = styled.div`
  height: 100%;
  padding-left: ${props => props.theme.spacings.byte};
  border-left: 1px solid ${props => props.theme.colors.gra100};
`;
