/* eslint-disable no-nested-ternary */
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import theme from '~/common/styled/theme';

const componentSize = theme.spacings.mega
const hoverBg = theme.colors.gra100
const gap = theme.spacings.bit
const spaceIdent = theme.spacings.kilo;

const calcDepthLeft = (value: number) => `padding-left: calc(${spaceIdent} * ${value});`

export const Action = styled<FontAwesomeIconProps>(props => <FontAwesomeIcon {...props} />)`
  color: ${theme?.colors?.gra500};
  z-index: 10;
  font-size: 12px;
  width: 15px;
`;

export const FloatAction = styled.div`
  position: absolute;
  height: 100%;
  background-color: ${hoverBg};
  padding: 0 10px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  column-gap: 5px;
  visibility: hidden;
`;

type IconProps = FontAwesomeIconProps & {
  type?: 'root' | 'children';
};

export const Icon = styled<IconProps>(props => <FontAwesomeIcon {...props} />)`
  color: ${theme?.colors?.gra500};
  z-index: 10;
  font-size: 12px;
  width: ${componentSize};
  ${({ type }) => type === 'root' ? `
    color: ${theme?.colors?.gra500};
    margin-left: 5px;
  ` : ''}
`;

export const Container = styled.div`
  position: relative;
  font-family: ${theme?.typography?.fontFamily};
`;

export const Box = styled.div``;

type ItemProps = {
  emphasis?: 'component' | 'children';
  depthLeft?: number;
};

export const Item = styled.div<ItemProps>`
  display: flex;
  padding-top: calc(${gap} / 2);
  padding-bottom: calc(${gap} / 2);
  position: relative;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${hoverBg};
    ${FloatAction} {
      visibility: visible;
    }
  }

  ${({ depthLeft }) => depthLeft ? calcDepthLeft(depthLeft) : ''}

  ${({ emphasis }) => emphasis === 'component' ? `
    background-color: ${theme?.colors?.gra100};
  ` : emphasis === 'children' ? `
    background-color: ${theme?.colors?.gra25};
  ` : ''}

`;

export const Shape = styled.div`
  display: inline-block;
  width: ${componentSize};
  height: ${componentSize};
  background-color: gray;
  border-radius: 50%;
  z-index: 10;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

type LabelProps = {
  type?: 'level' | 'condition';
};

export const Label = styled.div<LabelProps>`
  height: calc(${componentSize} + 3px);
  padding-left: 6px;
  display: inline-block;
  width: calc(100% - 20px);
  color: ${theme?.colors?.gra800};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding-top: 1px;

  ${({ type }) =>
    type === 'level' || type === 'condition'
      ? `color: ${theme?.colors?.gra500};`
      : ''}
`;

type ChildrenProps = {
  emphasis?: boolean;
};

export const Children = styled.div<ChildrenProps>`
  ${({ emphasis }) =>
    emphasis
      ? `
        background-color: ${theme?.colors?.gra25};
      `
      : ''}
`;

type Shape2Props = {
  shape?: 'ellipse' | 'diamond' | 'round-pentagon' | 'hexagon';
  image: string;
};

export const Shape2 = styled.div<Shape2Props>`
  background-color: gray;
  width: ${componentSize};
  height: ${componentSize};
  z-index: 10;
  &:after {
    content: '';
    background-image: url(${({ image }) => image});
    background-position: center;
    background-size: contain;
    display: block;
    position: relative;
    clip-path: inherit;
    width: 100%;
    height: 100%;
  }
  ${({ shape }) =>
    shape === 'ellipse'
      ? `
        border-radius: 50%;
        overflow: hidden;
      `
      : shape === 'diamond'
        ? `
        clip-path: polygon(93.53553% 46.46447%,94.33013% 47.5%,94.82963% 48.7059%,95% 50%,94.82963% 51.2941%,94.33013% 52.5%,93.53553% 53.53553%,53.53553% 93.53553%,52.5% 94.33013%,51.2941% 94.82963%,50% 95%,48.7059% 94.82963%,47.5% 94.33013%,46.46447% 93.53553%,6.46447% 53.53553%,5.66987% 52.5%,5.17037% 51.2941%,5% 50%,5.17037% 48.7059%,5.66987% 47.5%,6.46447% 46.46447%,46.46447% 6.46447%,47.5% 5.66987%,48.7059% 5.17037%,50% 5%,51.2941% 5.17037%,52.5% 5.66987%,53.53553% 6.46447%);
      `
        : shape === 'round-pentagon'
          ? `
        background-color: HotPink;
        clip-path: polygon(44.12215% 1.90983%,45.93263% 0.86455%,47.92088% 0.21852%,50% 0%,52.07912% 0.21852%,54.06737% 0.86455%,55.87785% 1.90983%,93.92011% 29.54915%,95.47371% 30.94801%,96.70251% 32.63932%,97.55283% 34.54915%,97.98748% 36.59404%,97.98748% 38.6846%,97.55283% 40.72949%,83.02198% 85.45085%,82.17166% 87.36068%,80.94286% 89.05199%,79.38926% 90.45085%,77.57878% 91.49613%,75.59053% 92.14216%,73.51141% 92.36068%,26.48859% 92.36068%,24.40947% 92.14216%,22.42122% 91.49613%,20.61074% 90.45085%,19.05714% 89.05199%,17.82834% 87.36068%,16.97802% 85.45085%,2.44717% 40.72949%,2.01252% 38.6846%,2.01252% 36.59404%,2.44717% 34.54915%,3.29749% 32.63932%,4.52629% 30.94801%,6.07989% 29.54915%);
      `
          : shape === 'hexagon'
            ? `
        background-color: HotPink;
        clip-path: polygon(94.33013% 47.5%,94.69846% 48.2899%,94.92404% 49.13176%,95% 50%,94.92404% 50.86824%,94.69846% 51.7101%,94.33013% 52.5%,74.33013% 87.14102%,73.83022% 87.85495%,73.21394% 88.47124%,72.5% 88.97114%,71.7101% 89.33948%,70.86824% 89.56505%,70% 89.64102%,30% 89.64102%,29.13176% 89.56505%,28.2899% 89.33948%,27.5% 88.97114%,26.78606% 88.47124%,26.16978% 87.85495%,25.66987% 87.14102%,5.66987% 52.5%,5.30154% 51.7101%,5.07596% 50.86824%,5% 50%,5.07596% 49.13176%,5.30154% 48.2899%,5.66987% 47.5%,25.66987% 12.85898%,26.16978% 12.14505%,26.78606% 11.52876%,27.5% 11.02886%,28.2899% 10.66052%,29.13176% 10.43495%,30% 10.35898%,70% 10.35898%,70.86824% 10.43495%,71.7101% 10.66052%,72.5% 11.02886%,73.21394% 11.52876%,73.83022% 12.14505%,74.33013% 12.85898%);
      `
            : ''}
`;

